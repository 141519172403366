import React, { useState, useEffect } from "react";
import Button from "../Components/Button";
import TableEntryHeadings from "../Components/TableEntryHeadings";
import TableEntryRows from "../Components/TableEntryRows";
import { useNavigate } from "react-router-dom";
import { GetAllInvestor } from "../Apis/Repos";
import Loader from "./Loader";
import { getInvestorsArray, setInvestorsArray } from "../GlobalVariables";
import { ERROR_CODES } from "../Constants";
import AddInvestor from "../Screens/AddInvestor";
import { deleteInvestorOrStartUp } from "../Apis/Repos";

export default function Investor({ setIsAddInvestor, setIsEditInvestor }) {
  const navigate = useNavigate();
  const tableHeadingRow = [
    { heading: "" },
    { heading: "" },
    { heading: "Name" },
    { heading: "Email" },
    { heading: "Type" },
    { heading: "Startup Stage" },
  ];

  //#region Variables

  const [investorsData, setInvestorsData] = useState(getInvestorsArray());
  const [investorAddEditDialogVisibility, setInvestorAddEditDialogVisibility] =
    useState(false);
  const [investorSelectedToEdit, setInvestorSelectedToEdit] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  //#endregion

  //#region Api Call
  useEffect(() => {
    if (getInvestorsArray().length > 0 && getInvestorsArray() !== null) {
      debugger;
    } else {
      setIsLoading(true);
      GetAllInvestor()
        .then((res) => {
          setIsLoading(false);
          debugger;
          if (res.data.success === ERROR_CODES.SUCCESS) {
            setInvestorsArray(res.data.result);
            debugger;
            console.log("GetAllInvestor Api Response", getInvestorsArray());
            setInvestorsData(getInvestorsArray());
          } else {
            alert("No Data found please try later");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("error", error);
        });
    }
  }, []);

  const onInvestorDeleteClickListenerApiCall = (investorId) => {
    deleteInvestorOrStartUp(investorId.Id)
      .then((res) => {
        if (res.data.success === ERROR_CODES.SUCCESS) {
          var afterRemove = investorsData.filter((x) => x.Id != investorId.Id);
          setInvestorsData(afterRemove);
          setInvestorsArray(afterRemove);
        } else {
          alert("Can't delete data, please try later");
        }
      })
      .catch((error) => {
        alert("Exception", error);
      });
  };

  //#endregion

  //#region other functions

  const onInvestorEditClickListener = (investorData) => {
    navigate("/Dashboard/investor-edit", {
      state: { investorDataToPass: investorData },
    });
  };

  //#endregion

  return (
    <div className="main__container">
      <div className="main__container__header">
        <div className="main__container__header__heading">Investor</div>
        <div className="main__container__header__buttons">
          <Button
            placeholder="Add Investor"
            className="primary__button"
            path="/Dashboard/investor-edit"
          />
        </div>
      </div>
      <div className="main__container__content">
        <div className="main__container__content__table">
          <div className="table__container__header">
            <div
              className="table__container__header__entry"
              style={{ minWidth: 150 }}
            ></div>
            <div
              className="table__container__header__entry"
              style={{ minWidth: 250 }}
            >
              Name
            </div>
            <div
              style={{ minWidth: 250 }}
              className="table__container__header__entry"
            >
              Email
            </div>
            <div
              style={{ minWidth: 250 }}
              className="table__container__header__entry"
            >
              Type
            </div>
            <div
              style={{ minWidth: 250 }}
              className="table__container__header__entry"
            >
              Startup Stage
            </div>
          </div>
          {
            isLoading ? (
              <Loader/>
            ) : (

          investorsData.length > 0 && investorsData !== null ? (
            investorsData.map((investor) => (
              <div class="entry__info__row">
                <div class="entry__info__row__text" style={{ minWidth: 150 }}>
                  <div>
                    <Button
                      onClick={() => onInvestorEditClickListener(investor)}
                      variant="rounded"
                      style={{ marginRight: 6 }}
                      svg={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-edit-2"
                        >
                          <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                        </svg>
                      }
                      className="rounded__btn__secondary"
                    />

                    <Button
                      // path={path}
                      onClick={() =>
                        onInvestorDeleteClickListenerApiCall(investor)
                      }
                      variant="rounded"
                      svg={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-trash"
                        >
                          <polyline points="3 6 5 6 21 6"></polyline>
                          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                        </svg>
                      }
                      type="button"
                      className="rounded__btn__primary"
                    />
                  </div>
                </div>
                <div class="entry__info__row__text" style={{ minWidth: 250 }}>
                  {investor.usermeta[0] ? investor.usermeta[0].UserValue : ""}
                </div>
                <div class="entry__info__row__text" style={{ minWidth: 250 }}>
                  {investor.Email}
                </div>
                <div class="entry__info__row__text" style={{ minWidth: 250 }}>
                  {investor.usermeta[1] ? investor.usermeta[1].UserValue : ""}
                </div>
                <div class="entry__info__row__text" style={{ minWidth: 250 }}>
                  {investor.usermeta[2] ? investor.usermeta[2].UserValue : ""}
                </div>
              </div>
            )
            )):<div style={{fontSize:"30px", textAlign: "center", marginTop: "50px", color: "black"}}>Data Not Found</div>
          ) }
        </div>
      </div>
      {/* {
        investorAddEditDialogVisibility ?
          <AddInvestor
            investorSelectedToEdit={investorSelectedToEdit}
            onDialogCrossClicked={() => {
              setInvestorAddEditDialogVisibility(false);
              setInvestorSelectedToEdit(null)
            }}
            onInvestorAddedSuccessfully={(investorData) => {
              investorsData.push(investorData);
              debugger
              setInvestorAddEditDialogVisibility(false);
            }}
            onInvestorEditedSuccessfully={(oldInvestorData, updatedInvestorData) => {
              var updatedInvestorIndex = investorsData.findIndex(
                (element) => element === oldInvestorData
              );
              investorsData[updatedInvestorIndex] = updatedInvestorData;
              setInvestorSelectedToEdit(null);
              setInvestorAddEditDialogVisibility(false);
            }}
          />
          : null
      } */}
    </div>
  );
}
