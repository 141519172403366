import React, { useState, useEffect } from "react";
import InputBox from "../Components/InputBox";
import { addClient } from "../Apis/Repos";
import { ERROR_CODES } from "../Constants";
import { getBase64 } from "../Utils/TextUtils";
import { isNullOrEmpty } from "../Utils/TextUtils";
import MultiSelectInput from "../Components/MultiSelectInput";
import { isURLInvalid } from "../Utils/Validations";
import Loader from "./Loader";

export default function AddClient(props) {
  let options = [
    { value: 1, label: "investor" },
    { value: 2, label: "startup" },
  ];

  useEffect(() => {
    onUserTypeEditClickListener();
  }, []);

  //#region other functions

  const onChangeClientUserTypehDropDownValue = (event) => {
    console.log("value", event.value);
    setClientUserType(event);
  };

  const isEdit = () => {
    return props.clientSelectedToEdit != null;
  };

  const fileSelectedHandler = async (event) => {
    if (event.currentTarget.files.length > 0){
    const element = event.currentTarget.files[0];
    await getBase64(element, (result) => {
      setClientImage_base64(result);
    });
  }
  };

  const onUserTypeEditClickListener = () => {
    if (clientToEdit !== null && clientToEdit.Type === 1) {
      setClientUserType((clientUserType = { label: "Investor" }));
      debugger;
    } else if (clientToEdit !== null && clientToEdit.Type === 2) {
      setClientUserType((clientUserType = { label: "startup" }));
      debugger;
    }
  };

  const onClientAddOrSaveClickListenerApiCall = () => {
    if (isEdit()) editClientApiCall();
    else addClientApiCall();
  };

  //#endregion

  //#region Variables
  const [clientId, setClientId] = useState(
    props.clientSelectedToEdit ? props.clientSelectedToEdit.Id : 0
  );

  var [clientName, setClientName] = useState(
    props.clientSelectedToEdit ? props.clientSelectedToEdit.Name : ""
  );
  const [clientNameErrorMessage, setClientNameErrorMessage] = useState("");
  const [
    clientNameErrorMessageVisibility,
    setClientNameErrorMessageVisibility,
  ] = useState(false);

  var [clientWebsite, setClientWebsite] = useState(
    props.clientSelectedToEdit ? props.clientSelectedToEdit.Website : ""
  );
  const [clientWebsiteErrorMessage, setClientWebsiteErrorMessage] =
    useState("");
  const [
    clientWebsiteErrorMessageVisibility,
    setClientWebsiteErrorMessageVisibility,
  ] = useState(false);

  var clientToEdit =
    isEdit() && props.clientSelectedToEdit != null
      ? props.clientSelectedToEdit
      : null;
  debugger;
  var [clientUserType, setClientUserType] = useState(
    props.clientSelectedToEdit ? props.clientSelectedToEdit.Type : 0
  );
  var [clientUserTypeErrorMessage, setClientUserTypeErrorMessage] =
    useState("");
  const [
    clientUserTypeErrorMessageVisibility,
    setClientUserTypeErrorMessageVisibility,
  ] = useState(false);

  const [clientImage, setClientImage] = useState(
    props.clientSelectedToEdit ? props.clientSelectedToEdit.ImagePath : null
  );
  const [clientImage_base64, setClientImage_base64] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region API Call
  const addClientApiCall = () => {
    let clientInfo = {
      Id: 0,
      Name: clientName,
      ImageBase64: clientImage_base64,
      Website: clientWebsite,
      Type: clientUserType.value,
    };
    debugger;
    if (isViewValid()) {
      setIsLoading(true);
      addClient(clientInfo)
        .then((res) => {
          setIsLoading(false);
          debugger;
          if (res.data.success === ERROR_CODES.SUCCESS) {
            debugger;
            props.onClientAddedSuccessfully(res.data.result);
            debugger;
          } else {
            alert("Client cannot be added, please try later");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          alert("Exception"+ error);
        });
    }
  };

  const editClientApiCall = () => {
    let clientInfo = {
      Id: clientId,
      Name: clientName,
      ImageBase64: clientImage_base64,
      ImagePath: clientImage,
      Website: clientWebsite,
      Type: clientUserType.value,
    };
    debugger;
    if (isViewValid()) {
      setIsLoading(true);
      addClient(clientInfo)
        .then((res) => {
          setIsLoading(false);
          if (res.data.success === ERROR_CODES.SUCCESS) {
            debugger;
            props.onClientEditedSuccessfully(
              props.clientSelectedToEdit,
              res.data.result
            );
            debugger;
          } else {
            alert("Client can not be edited, please try later");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          alert("Exception"+ error);
        });
    }
  };

  //#endregion

  //#region Client Name Validation
  const onClientNameTextChangeListener = (event) => {
    setClientName((clientName = event.currentTarget.value));
    setClientNameErrorMessageAndVisibilityAndIsValid(clientName);
  };
  const setClientNameErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setClientNameErrorMessageAndVisibility(true, "Name must not be empty");
    } else {
      setClientNameErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;
  };
  const setClientNameErrorMessageAndVisibility = (visibility, text) => {
    setClientNameErrorMessageVisibility(visibility);
    setClientNameErrorMessage(text);
  };
  //#endregion

  //#region Client Website Validation
  const onClientWebsiteTextChangeListener = (event) => {
    setClientWebsite((clientWebsite = event.currentTarget.value));
    setClientWebsiteErrorMessageAndVisibilityAndIsValid(clientWebsite);
  };
  const setClientWebsiteErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setClientWebsiteErrorMessageAndVisibility(
        true,
        "Website must not be empty"
      );
    } else if (isURLInvalid(enteredText)) {
      setClientWebsiteErrorMessageAndVisibility(true, "Enter a valid URL");
    } else {
      setClientWebsiteErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;
  };
  const setClientWebsiteErrorMessageAndVisibility = (visibility, text) => {
    setClientWebsiteErrorMessageVisibility(visibility);
    setClientWebsiteErrorMessage(text);
  };
  //#endregion

  //#region Client User Type Validation
  const onClienUserTypeTextChangeListener = (event) => {
    setClientUserType((clientUserType = event));
    setClientUserTypeErrorMessageAndVisibilityAndIsValid(clientUserType);
  };
  const setClientUserTypeErrorMessageAndVisibilityAndIsValid = (
    enteredText
  ) => {
    var isViewValid = false;
    if (enteredText === 0) {
      setClientUserTypeErrorMessageAndVisibility(
        true,
        "User Type must be Selected"
      );
      debugger;
    } else {
      setClientUserTypeErrorMessageAndVisibility(false, "");
      debugger;
      isViewValid = true;
    }
    return isViewValid;
  };
  const setClientUserTypeErrorMessageAndVisibility = (visibility, text) => {
    setClientUserTypeErrorMessageVisibility(visibility);
    setClientUserTypeErrorMessage(text);
  };
  //#endregion

  //#region View Valid
  const isViewValid = () => {
    var viewValid =
      setClientNameErrorMessageAndVisibilityAndIsValid(clientName);
    debugger;
    if (viewValid)
      viewValid =
        setClientWebsiteErrorMessageAndVisibilityAndIsValid(clientWebsite);
    debugger;
    if (viewValid) {
      viewValid =
        setClientUserTypeErrorMessageAndVisibilityAndIsValid(clientUserType);
    }
    debugger;
    return viewValid;
  };
  //#endregion

  return (
    <div className="popup__container">
      {
        isLoading ? (
          <Loader/>
        ) : (
      <div action="" className="popup__container__form">
        <div className="popup__container__form__header">
          <div>{isEdit() ? "Edit Client" : "Add Client"}</div>
          <button
            onClick={() => {
              props.onDialogCrossClicked(false);
            }}
            className="popup__container__form__close__btn"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14.829"
              height="14.829"
              viewBox="0 0 14.829 14.829"
            >
              <g id="x" transform="translate(-4.586 -4.586)">
                <line
                  id="Line_20"
                  data-name="Line 20"
                  x1="12"
                  y2="12"
                  transform="translate(6 6)"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
                <line
                  id="Line_21"
                  data-name="Line 21"
                  x2="12"
                  y2="12"
                  transform="translate(6 6)"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </g>
            </svg>
          </button>
        </div>

        <div className="popup__container__form__heading">Client Name</div>
        <InputBox
          placeholder="Name"
          type="text"
          value={clientName}
          error={clientNameErrorMessageVisibility}
          errorMessage={clientNameErrorMessage}
          onChange={(event) => onClientNameTextChangeListener(event)}
        />
        <div className="popup__container__form__heading">Website</div>
        <InputBox
          placeholder="Website"
          type="text"
          value={clientWebsite}
          error={clientWebsiteErrorMessageVisibility}
          errorMessage={clientWebsiteErrorMessage}
          onChange={(event) => onClientWebsiteTextChangeListener(event)}
        />
        <div className="popup__container__form__heading">User Type</div>
        <InputBox
          placeholder="User Type"
          variant="select"
          // isMulti={false}
          options={options}
          value={clientUserType}
          error={clientUserTypeErrorMessageVisibility}
          errorMessage={clientUserTypeErrorMessage}
          onChange={(e) => onClienUserTypeTextChangeListener(e)}
        />
        <div
          className="popup__container__form__heading"
          style={{ marginTop: "1em" }}
        >
          Upload Image
        </div>
        {isEdit() ? (
          <InputBox
            variant="upload"
            src={
              clientImage_base64 === null
                ? process.env.REACT_APP_API_URL + clientImage
                : clientImage_base64
            }
            onChange={fileSelectedHandler}
          />
        ) : (
          <>
            <InputBox
              variant="upload"
              src={clientImage_base64}
              onChange={fileSelectedHandler}
            />
          </>
        )}
        <button
          onClick={() => {
            onClientAddOrSaveClickListenerApiCall();
          }}
          style={{ marginTop: "1em", marginBottom: "1em" }}
          className="primary__button"
        >
          {isEdit() ? "Save" : "Add"}
        </button>
        {/* <div style={{ marginBottom: "0px" }}>Client Name</div>
        <InputBox
          placeholder="Name"
          type="text"
          value={clientName}
          error={clientNameErrorMessageVisibility}
          errorMessage={clientNameErrorMessage}
          onChange={(event) => onClientNameTextChangeListener(event)}
        />
        <p style={{ marginTop: "0px" }}>abc</p> */}
      </div>
        )
      }
    </div>
  );
}
