import React, { useState, useEffect } from "react";
import Button from "../Components/Button";
import TableEntryHeadings from "../Components/TableEntryHeadings";
import TableEntryRows from "../Components/TableEntryRows";
import Loader from "./Loader";
import tableEntryPic from "../Assets/tableEntryPic.png";
import { getAllFAQs, deleteFAQ } from "../Apis/Repos";
import { ERROR_CODES } from "../Constants";
import AddQuestion from "./AddQuestion";

export default function FAQ({ setIsAddQuestion, setIsEditQuestion }) {
  const tableHeadingRow = [
    { heading: "" },
    { heading: "" },
    { heading: "" },
    { heading: "" },
    { heading: "Question" },
    { heading: "" },
    { heading: "" },
    { heading: "Answer" },
  ];

  useEffect(() => {
    getFAQsListApiCall();
  }, []);

  //#region Variable

  const [faqsData, setFAQsData] = useState([]);
  const [faqAddEditDialogVisibility, setFaqAddEditDialogVibility] =
    useState(false);
  const [faqSelectedToEdit, setFaqSelectedToEdit] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  //#endregion

  //#region Api Call
  const getFAQsListApiCall = () => {
    setIsLoading(true);
    getAllFAQs()
      .then((res) => {
        setIsLoading(false);
        if (res.data.success === ERROR_CODES.SUCCESS) {
          setFAQsData(res.data.result);
        } else {
          alert("Can't load data, please try later");
        }
        console.log("getAllFAQs Response", res.data.result);
      })
      .catch((error) => {
        setIsLoading(false);
        alert("Exception"+ error);
      });
  };

  const deleteFAQApiCall = (faqId) => {
    deleteFAQ(faqId.Id)
      .then((res) => {
        if (res.data.success === ERROR_CODES.SUCCESS) {
          var afterRemove = faqsData.filter((x) => x.Id != faqId.Id);
          setFAQsData(afterRemove);
        } else {
          alert("Can't delete, please try later ");
        }
      })
      .catch((error) => {
        alert("Exception", error);
      });
  };
  //#endregion

  //#region other functions
  const onFAQEditClickListener = (faqData) => {
    setFaqSelectedToEdit(faqData);
    setFaqAddEditDialogVibility(true);
  };
  //#endregion

  return (
    <div className="main__container">
      <div className="main__container__header">
        <div className="main__container__header__heading">FAQ</div>
        <div className="main__container__header__buttons">
          <Button
            placeholder="Add Question"
            className="primary__button"
            onClick={() => setFaqAddEditDialogVibility(true)}
          />
        </div>
      </div>
      <div className="main__container__content">
        <div className="main__container__content__table">
          <div className="table__container__header">
            <div
              className="table__container__header__entry"
              style={{ minWidth: 180 }}
            ></div>
            <div
              className="table__container__header__entry"
              style={{ minWidth: 450 }}
            >
              Question
            </div>
            <div
              className="table__container__header__entry"
              style={{ minWidth: 180 }}
            >
              Answer
            </div>
          </div>
          {
            isLoading ? (
              <Loader/>
            ) : (

          faqsData.length > 0 && faqsData !== null ? (
            faqsData.map((data) => (
              <div class="entry__info__row">
                <div class="entry__info__row__text" style={{ minWidth: 180 }}>
                  <div style={{ marginRight: 6 }}>
                    <Button
                      variant="rounded"
                      svg={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-edit-2"
                        >
                          <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                        </svg>
                      }
                      type="button"
                      className="rounded__btn__secondary"
                      onClick={() => onFAQEditClickListener(data)}
                    />
                  </div>
                  <div>
                    <Button
                      variant="rounded"
                      svg={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-trash"
                        >
                          <polyline points="3 6 5 6 21 6"></polyline>
                          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                        </svg>
                      }
                      type="button"
                      className="rounded__btn__primary"
                      onClick={() => deleteFAQApiCall(data)}
                    />
                  </div>
                </div>
                <div class="entry__info__row__text" style={{ minWidth: 450 }}>
                  {data.Question}
                </div>
                <div
                  className="entry__info__row__text"
                  style={{ minWidth: 150 }}
                >
                  <a href="">
                    View Message
                    <div className="entry__info__row__text__message">
                      {data.Answer}
                    </div>
                  </a>
                </div>
              </div>
            )
            )):<div style={{fontSize:"30px", textAlign: "center", marginTop: "50px", color: "black"}}>Data Not Found</div>
          ) }
        </div>
      </div>
      {faqAddEditDialogVisibility ? (
        <AddQuestion
          faqSelectedToEdit={faqSelectedToEdit}
          onDialogCrossClicked={() => {
            setFaqAddEditDialogVibility(false);
            setFaqSelectedToEdit(null);
          }}
          onFAQAddedSuccessfully={(faqData) => {
            faqsData.push(faqData);
            // getFAQsListApiCall();
            setFaqAddEditDialogVibility(false);
          }}
          onFAQEditedSuccessfully={(oldFAQ, updatedFAQ) => {
            var updatedFAQIndex = faqsData.findIndex(
              (element) => element === oldFAQ
            );
            faqsData[updatedFAQIndex] = updatedFAQ;
            setFaqAddEditDialogVibility(false);
          }}
        />
      ) : null}
    </div>
  );
}
