import React, { useState, useEffect } from "react";
import Button from "../Components/Button";
import TableEntryHeadings from "../Components/TableEntryHeadings";
import TableEntryRows from "../Components/TableEntryRows";
import { GetAllStartup } from "../Apis/Repos";
import Loader from "./Loader";
import tableEntryPic from "../Assets/tableEntryPic.png";
import { ERROR_CODES } from "../Constants";
import { getStartupArray, setStartupArray } from "../GlobalVariables";
import AddStartup from "../Screens/AddStartup";
import { useNavigate } from "react-router-dom";
import { deleteInvestorOrStartUp } from "../Apis/Repos";

export default function Startup({ setIsAddStartup, setIsEditStartup }) {
  const tableHeadingRow = [
    { heading: "" },
    { heading: "" },
    { heading: "Image" },
    { heading: "Name" },
    { heading: "Website" },
    { heading: "Industry" },
    { heading: "Startup Stage" },
    { heading: "Startup Model" },
  ];
  const navigate = useNavigate();

  //#region Variables
  const [startupData, setStartupData] = useState(getStartupArray());
  const [startupAddEditDialogVisibility, setStartupAddEditDialogVisibility] =
    useState(false);
    const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region other functions
  const onStartUpEditClickListener = (data) => {
    navigate("/Dashboard/startup-edit", { state: { startUpDataToPass: data } });
  };
  const onStartUpDetailClickListener = (data) => {
    navigate("/Dashboard/startup-details", {
      state: { startUpDataToPass: data },
    });
  };
  //#endregion

  //#region Api Call
  useEffect(() => {
    if (getStartupArray().length > 0 && getStartupArray() !== null) {
      debugger;
    } else {
      setIsLoading(true);
      GetAllStartup()
        .then((res) => {
          setIsLoading(false);
          if (res.data.success === ERROR_CODES.SUCCESS) {
            debugger;
            setStartupArray(res.data.result);
            setStartupData(getStartupArray());
            debugger;
          }
        })
        .catch((error) => {
          setIsLoading(false);
          alert("Exception"+ error);
        });
    }
  }, []);

  const onStartUpDeleteClickListenerApiCall = (startUpId) => {
    deleteInvestorOrStartUp(startUpId.Id)
      .then((res) => {
        if (res.data.success === ERROR_CODES.SUCCESS) {
          var afterRemove = startupData.filter((x) => x.Id !== startUpId.Id);
          setStartupData(afterRemove);
          setStartupArray(afterRemove);
        } else {
          alert("Can't delete data, please try later");
        }
      })
      .catch((error) => {
        alert("Exception", error);
      });
  };

  //#endregion

  return (
    <div className="main__container">
      <div className="main__container__header">
        <div className="main__container__header__heading">Startup</div>
        <div className="main__container__header__buttons">
          <Button
            placeholder="Add Startup"
            className="primary__button"
            path="/Dashboard/startup-edit"
          />
        </div>
      </div>
      <div className="main__container__content">
        <div className="main__container__content__table">
          <div className="table__container__header">
            <div
              className="table__container__header__entry"
              style={{ minWidth: 180 }}
            ></div>
            <div
              className="table__container__header__entry"
              style={{ minWidth: 150 }}
            >
              Image
            </div>
            <div
              className="table__container__header__entry"
              style={{ minWidth: 200 }}
            >
              Name
            </div>
            <div
              style={{ minWidth: 280 }}
              className="table__container__header__entry"
            >
              Website
            </div>
            <div
              style={{ minWidth: 180 }}
              className="table__container__header__entry"
            >
              Industry
            </div>
            <div
              style={{ minWidth: 180 }}
              className="table__container__header__entry"
            >
              Startup Stage
            </div>
            <div
              style={{ minWidth: 180 }}
              className="table__container__header__entry"
            >
              Startup Model
            </div>
          </div>

          {
            isLoading ? (
              <Loader/>
            ) : (

          startupData.length > 0 && startupData !== null ? (
            startupData.map((startup) => (
              <div class="entry__info__row">
                <div style={{ minWidth: 180 }} class="entry__info__row__text">
                  <div style={{ marginRight: 6 }}>
                    <Button
                      variant="rounded"
                      svg={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-edit-2"
                        >
                          <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                        </svg>
                      }
                      type="button"
                      className="rounded__btn__secondary"
                      onClick={() => onStartUpEditClickListener(startup)}
                      // path="/Dashboard/startup-edit"
                    />
                  </div>
                  <div style={{ marginRight: 6 }}>
                    <Button
                      variant="rounded"
                      svg={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-file"
                        >
                          <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                          <polyline points="13 2 13 9 20 9"></polyline>
                        </svg>
                      }
                      type="button"
                      className="rounded__btn__secondary"
                      onClick={() => onStartUpDetailClickListener(startup)}
                      // path="/Dashboard/startup-details"
                    />
                  </div>
                  <div>
                    <Button
                      variant="rounded"
                      svg={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-trash"
                        >
                          <polyline points="3 6 5 6 21 6"></polyline>
                          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                        </svg>
                      }
                      type="button"
                      className="rounded__btn__primary"
                      onClick={() =>
                        onStartUpDeleteClickListenerApiCall(startup)
                      }
                    />
                  </div>
                </div>
                <div
                  className="entry__info__row__text"
                  style={{ minWidth: 150 }}
                >
                  <img
                    src={
                      process.env.REACT_APP_API_URL + startup.attachment_path
                    }
                    alt="tableEntryPic"
                    className="entry__info__row__text__img"
                  />
                </div>
                <div class="entry__info__row__text" style={{ minWidth: 200 }}>
                  {startup.usermeta[0] ? startup.usermeta[0].UserValue : ""}
                </div>
                <div class="entry__info__row__text" style={{ minWidth: 280 }}>
                  {startup.Email}
                </div>
                <div class="entry__info__row__text" style={{ minWidth: 180 }}>
                  {startup.usermeta[2] ? startup.usermeta[2].UserValue : ""}
                </div>
                <div class="entry__info__row__text" style={{ minWidth: 180 }}>
                  {startup.usermeta[3] ? startup.usermeta[3].UserValue : ""}
                </div>
                <div class="entry__info__row__text" style={{ minWidth: 180 }}>
                  {startup.usermeta[4] ? startup.usermeta[4].UserValue : ""}
                </div>
              </div>
            )
          
            )):<div style={{fontSize:"30px", textAlign: "center", marginTop: "50px", color: "black"}}>Data Not Found</div>
          ) }
        </div>
      </div>
    </div>
  );
}
