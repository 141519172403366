import React, { useState, useEffect } from "react";
import Button from "../Components/Button";
import TableEntryHeadings from "../Components/TableEntryHeadings";
import TableEntryRows from "../Components/TableEntryRows";
import Loader from "./Loader";
import tableEntryPic from "../Assets/tableEntryPic.png";
import { GetAllClients, deleteClient } from "../Apis/Repos";
import { ERROR_CODES } from "../Constants";
import AddClient from "../Screens/AddClient";

export default function Client({ setIsEditClient, setIsAddClient }) {
  const tableHeadingRow = [
    { heading: "" },
    { heading: "Client Image" },
    { heading: "Client Name" },
    { heading: "Client Website" },
  ];

  //#region Variables

  const [clientData, setClientData] = useState([]);
  const [addEditClientDialogVisibility, setAddEditClientDialogVisibility] =
    useState(false);
  const [clientSelectedToEdit, setClientSelectedToEdit] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  //#endregion

  useEffect(() => {
    debugger;
    getClientListApiCall();
  }, []);

  //#region API Call
  const getClientListApiCall = () => {
    setIsLoading(true);
    GetAllClients()
      .then((res) => {
        setIsLoading(false);
        if (res.data.success === ERROR_CODES.SUCCESS) {
          debugger;
          setClientData(res.data.result);
        } else {
          alert("Can't load data, please try later");
        }
        console.log("GetAllClients Response", res.data.result);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error", error);
      });
  };

  const onClientDeleteClickListenerApiCall = (clientId) => {
    deleteClient(clientId.Id)
      .then((res) => {
        if (res.data.success === ERROR_CODES.SUCCESS) {
          var afterRemove = clientData.filter((x) => x.Id != clientId.Id);
          setClientData(afterRemove);
        } else {
          alert("Can't delete data, please try later");
        }
      })
      .catch((error) => {
        alert("Exception", error);
      });
  };
  //#endregion

  //#region other function
  const onClientEditClickListener = (clientData) => {
    setClientSelectedToEdit(clientData);
    debugger;
    setAddEditClientDialogVisibility(true);
  };

  const imageToShow = (clientImage) => {
    let image = clientImage;
    return image;
  };
  //#endregion

  return (
    <div className="main__container">
      <div className="main__container__header">
        <div className="main__container__header__heading">Client</div>
        <div className="main__container__header__buttons">
          <Button
            placeholder="Add Client"
            className="primary__button"
            onClick={() => setAddEditClientDialogVisibility(true)}
          />
        </div>
      </div>
      <div className="main__container__content">
        <div className="main__container__content__table">
          <div className="table__container__header">
            <div
              className="table__container__header__entry"
              style={{ minWidth: 150 }}
            ></div>
            <div className="table__container__header__entry">Client Image</div>
            <div
              className="table__container__header__entry"
              style={{ minWidth: 200 }}
            >
              Client Name
            </div>
            <div
              style={{ minWidth: 600 }}
              className="table__container__header__entry"
            >
              Client Website
            </div>
          </div>
          {
            isLoading ? (
              <Loader/>
            ) : (

          clientData.length > 0 && clientData !== null ? (
            clientData.map((client) => (
              <div class="entry__info__row">
                <div
                  className="entry__info__row__text"
                  style={{ minWidth: 150 }}
                >
                  <div style={{ marginRight: 6 }}>
                    <Button
                      variant="rounded"
                      svg={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-edit-2"
                        >
                          <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                        </svg>
                      }
                      type="button"
                      className="rounded__btn__secondary"
                      onClick={() => onClientEditClickListener(client)}
                    />
                  </div>
                  <div>
                    <Button
                      variant="rounded"
                      svg={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-trash"
                        >
                          <polyline points="3 6 5 6 21 6"></polyline>
                          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                        </svg>
                      }
                      type="button"
                      className="rounded__btn__primary"
                      onClick={() => onClientDeleteClickListenerApiCall(client)}
                    />
                  </div>
                </div>
                <div className="entry__info__row__text">
                  <img
                    src={imageToShow(
                      process.env.REACT_APP_API_URL + client.ImagePath
                    )}
                    // src={tableEntryPic}
                    alt="tableEntryPic"
                    className="entry__info__row__text__img__client"
                  />
                </div>
                <div class="entry__info__row__text" style={{ minWidth: 200 }}>
                  {client.Name}
                </div>
                <div class="entry__info__row__text" style={{ minWidth: 600 }}>
                  {client.Website}
                </div>
                {/* <div style={{ marginRight: 10 }}>
                  <Button
                    placeholder="Edit"
                    type="button"
                    className="secondary__button"
                    onClick={() => onClientEditClickListener(client)}
                  />
                </div>
                <div style={{ marginRight: 10 }}>
                  <Button
                    placeholder="Delete"
                    type="button"
                    className="primary__button"
                    onClick={() => { }}
                  />
                </div> */}
              </div>
            )
            )) :<div style={{fontSize:"30px", textAlign: "center", marginTop: "50px", color: "black"}}>Data Not Found</div>
          ) }
        </div>
      </div>
      {addEditClientDialogVisibility ? (
        <AddClient
          clientSelectedToEdit={clientSelectedToEdit}
          onDialogCrossClicked={() => {
            setAddEditClientDialogVisibility(false);
            setClientSelectedToEdit(null);
          }}
          onClientAddedSuccessfully={(addedClientData) => {
            clientData.push(addedClientData);
            // getClientListApiCall();
            setAddEditClientDialogVisibility(false);
          }}
          onClientEditedSuccessfully={(oldClientData, updatedClientData) => {
            var updatedClientIndex = clientData.findIndex(
              (element) => element === oldClientData
            );
            debugger;
            clientData[updatedClientIndex] = updatedClientData;
            debugger;
            setClientSelectedToEdit(null);
            setAddEditClientDialogVisibility(false);
          }}
        />
      ) : null}
    </div>
  );
}
