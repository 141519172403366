import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import startup__details__img from "../Assets/startup__details__img.png";
import InputBox from "../Components/InputBox";
import { addStartUp } from "../Apis/Repos";
import { ERROR_CODES, USER_KEYS } from "../Constants";
import { getStartupArray, setStartupArray } from "../GlobalVariables";
import { getBase64 } from "../Utils/TextUtils";
import { isEmailInvalid, isURLInvalid } from "../Utils/Validations";
import { isNullOrEmpty } from "../Utils/TextUtils";
import Loader from "./Loader";

export default function AddEditStartup(props) {
  const navigate = useNavigate();
  const location = useLocation();
  console.log("location.state", location.state);

  const isEdit = () => {
    return location.state != null;
  };

  //#region Options
  let startupIndustryOptions = [
    { value: "Start-Up Industry", label: "E-commerce" },
    { value: "Start-Up Industry", label: "HealthTech" },
    { value: "Start-Up Industry", label: "EdTech" },
    { value: "Start-Up Industry", label: "FinTech" },
    { value: "Start-Up Industry", label: "AgriTech" },
    { value: "Start-Up Industry", label: "Food and Nutrition" },
    { value: "Start-Up Industry", label: "Hospitality" },
    { value: "Start-Up Industry", label: "Transport" },
    { value: "Start-Up Industry", label: "Logistics" },
    { value: "Start-Up Industry", label: "SaaS" },
    { value: "Start-Up Industry", label: "IT Solution" },
  ];

  const onChangeStartupIndustryDropDownValue = (event) => {
    setStartupIndustry(event);
  };

  let startupStageOptions = [
    { value: "Start-Up Stage", label: "Ideation" },
    { value: "Start-Up Stage", label: "Pre-seed" },
    { value: "Start-Up Stage", label: "Seed" },
    { value: "Start-Up Stage", label: "MVP" },
    { value: "Start-Up Stage", label: "Growth" },
  ];

  const onChangeStartupStageDropDownValue = (event) => {
    setStartupStage(event);
  };

  let startupModelOptions = [
    { value: "Start-Up Model", label: "B2B" },
    { value: "Start-Up Model", label: "B2C" },
    { value: "Start-Up Model", label: "C2B" },
    { value: "Start-Up Model", label: "C2C" },
  ];

  const onChangeStartupModelDropDownValue = (event) => {
    setStartupModel(event);
  };

  let startupPlanOnFundingOptions = [
    { value: "Start-Up Plan On Raising Funding", label: "Immediately" },
    {
      value: "Start-Up Plan On Raising Funding",
      label: "Within the next 6 months.",
    },
    {
      value: "Start-Up Plan On Raising Funding",
      label: "Within the next 1 year.",
    },
  ];

  const onChangeStartupPlanOnFundingDropDownValue = (event) => {
    setStartupPlanOnRaisingFund(event);
  };

  let startupFundingBeforeOptions = [
    { value: "Has Your Start-Up Raised Funding Before?", label: "Yes" },
    { value: "Has Your Start-Up Raised Funding Before?", label: "No" },
  ];

  const onChangeStartupFundBeforeDropDownValue = (event) => {
    setStartupFundingBefore(event);
  };
  //#endregion

  //#region Variables
  const [startUpId, setStartUpId] = useState(
    location.state ? location.state.startUpDataToPass.Id : 0
  );
  const [startupUserId, setStartUpUserId] = useState(
    location.state && location.state.startUpDataToPass.usermeta[0]
      ? location.state.startUpDataToPass.usermeta[0].UserId
      : 0
  );

  var [startupName, setStartupName] = useState(
    location.state && location.state.startUpDataToPass.usermeta[0]
      ? location.state.startUpDataToPass.usermeta[0].UserValue
      : ""
  );
  const [
    startupNameErrorMessageVisibility,
    setStartupNameErrorMessageVisibility,
  ] = useState(false);
  const [startupNameErrorMessage, setStartupNameErrorMessage] = useState("");

  var [startupEmail, setStartupEmail] = useState(
    location.state ? location.state.startUpDataToPass.Email : ""
  );
  const [
    startupEmailErrorMessageVisibility,
    setStartupEmailErrorMessageVisibility,
  ] = useState(false);
  const [startupEmailErrorMessage, setStartupEmailErrorMessage] = useState("");

  var [startupWebsite, setStartupWebsite] = useState(
    location.state && location.state.startUpDataToPass.usermeta[1]
      ? location.state.startUpDataToPass.usermeta[1].UserValue
      : ""
  );
  const [
    startupWebsiteErrorMessageVisibility,
    setStartupWebsiteErrorMessageVisibility,
  ] = useState(false);
  const [startupWebsiteErrorMessage, setStartupWebsiteErrorMessage] =
    useState("");

  // var startupIndustryToEdit = location.state && location.state.startUpDataToPass.usermeta[2] ? location.state.startUpDataToPass.usermeta[2] : null
  var [startupIndustry, setStartupIndustry] = useState(
    location.state && location.state.startUpDataToPass.usermeta[2]
      ? { label: location.state.startUpDataToPass.usermeta[2].UserValue }
      : 0
  );

  const [
    startupIndustryErrorMessageVisibility,
    setStartupIndustryErrorMessageVisibility,
  ] = useState(false);
  const [startupIndustryErrorMessage, setStartupIndustryErrorMessage] =
    useState("");

  var [startupStage, setStartupStage] = useState(
    location.state && location.state.startUpDataToPass.usermeta[3]
      ? { label: location.state.startUpDataToPass.usermeta[3].UserValue }
      : 0
  );
  const [
    startupStageErrorMessageVisibility,
    setStartupStageErrorMessageVisibility,
  ] = useState(false);
  const [startupStageErrorMessage, setStartupStageErrorMessage] = useState("");

  var [startupModel, setStartupModel] = useState(
    location.state && location.state.startUpDataToPass.usermeta[4]
      ? { label: location.state.startUpDataToPass.usermeta[4].UserValue }
      : 0
  );
  const [
    startupModelErrorMessageVisibility,
    setStartupModelErrorMessageVisibility,
  ] = useState(false);
  const [startupModelErrorMessage, setStartupModelErrorMessage] = useState("");

  var [startupProblem, setStartupProblem] = useState(
    location.state && location.state.startUpDataToPass.usermeta[5]
      ? location.state.startUpDataToPass.usermeta[5].UserValue
      : ""
  );
  const [
    startupProblemErrorMessageVisibility,
    setStartupProblemErrorMessageVisibility,
  ] = useState(false);
  const [startupProblemErrorMessage, setStartupProblemErrorMessage] =
    useState("");

  var [startupDescription, setStartupDescription] = useState(
    location.state && location.state.startUpDataToPass.usermeta[6]
      ? location.state.startUpDataToPass.usermeta[6].UserValue
      : ""
  );
  const [
    startupDescriptionErrorMessageVisibility,
    setStartupDescriptionErrorMessageVisibility,
  ] = useState(false);
  const [startupDescriptionErrorMessage, setStartupDescriptionErrorMessage] =
    useState("");

  var [startupTechnology, setStartupTechnology] = useState(
    location.state && location.state.startUpDataToPass.usermeta[7]
      ? location.state.startUpDataToPass.usermeta[7].UserValue
      : ""
  );
  const [
    startupTechnologyErrorMessageVisibility,
    setStartupTechnologyErrorMessageVisibility,
  ] = useState(false);
  const [startupTechnologyErrorMessage, setStartupTechnologyErrorMessage] =
    useState("");

  var [startupLocalCompetitors, setStartupLocalCompetitors] = useState(
    location.state && location.state.startUpDataToPass.usermeta[8]
      ? location.state.startUpDataToPass.usermeta[8].UserValue
      : ""
  );
  const [
    startupLocalCompetitorsErrorMessageVisibility,
    setStartupLocalCompetitorsErrorMessageVisibility,
  ] = useState(false);
  const [
    startupLocalCompetitorsErrorMessage,
    setStartupLocalCompetitorsErrorMessage,
  ] = useState("");

  var [startupGlobalCompetitors, setStartupGlobalCompetitors] = useState(
    location.state && location.state.startUpDataToPass.usermeta[9]
      ? location.state.startUpDataToPass.usermeta[9].UserValue
      : ""
  );
  const [
    startupGlobalCompetitorsErrorMessageVisibility,
    setStartupGlobalCompetitorsErrorMessageVisibility,
  ] = useState(false);
  const [
    startupGlobalCompetitorsErrorMessage,
    setStartupGlobalCompetitorsErrorMessage,
  ] = useState("");

  var [startupUniqueValueProposition, setStartupUniqueValueProposition] =
    useState(
      location.state && location.state.startUpDataToPass.usermeta[10]
        ? location.state.startUpDataToPass.usermeta[10].UserValue
        : ""
    );
  const [
    startupUuniqueValuePropositionErrorMessageVisibility,
    setStartupUniqueValuePropositionErrorMessageVisibility,
  ] = useState(false);
  const [
    startupUniqueValuePropositionErrorMessage,
    setStartupUniqueValuePropositionErrorMessage,
  ] = useState("");

  var [startupTraction, setStartupTraction] = useState(
    location.state && location.state.startUpDataToPass.usermeta[11]
      ? location.state.startUpDataToPass.usermeta[11].UserValue
      : ""
  );
  const [
    startupTractionErrorMessageVisibility,
    setStartupTractionErrorMessageVisibility,
  ] = useState(false);
  const [startupTractionErrorMessage, setStartupTractionErrorMessage] =
    useState("");

  var [startupTotalMembersOfFundingTeam, setStartupTotalMembersOfFundingTeam] =
    useState(
      location.state && location.state.startUpDataToPass.usermeta[12]
        ? location.state.startUpDataToPass.usermeta[12].UserValue
        : ""
    );
  const [
    startupTotalMembersOfFundingTeamErrorMessageVisibility,
    setStartupTotalMembersOfFundingTeamErrorMessageVisibility,
  ] = useState(false);
  const [
    startupTotalMembersOffundingTeamErrorMessage,
    setStartupTotalMembersOfFundingTeamErrorMessage,
  ] = useState("");

  var [
    startupDetailsOfFundingTeamMembers,
    setStartupDetailsOfFundingTeamMembers,
  ] = useState(
    location.state && location.state.startUpDataToPass.usermeta[13]
      ? location.state.startUpDataToPass.usermeta[13].UserValue
      : ""
  );
  const [
    startupDetailsOfFundingTeamMembersErrorMessageVisibility,
    setStartupDetailsOfFundingTeamMembersErrorMessageVisibility,
  ] = useState(false);
  const [
    startupDetailsOfFundingTeamMembersErrorMessage,
    setStartupDetailsOfFundingTeamMembersErrorMessage,
  ] = useState("");

  var [startupContactDetails, setStartupContactDetails] = useState(
    location.state && location.state.startUpDataToPass.usermeta[14]
      ? location.state.startUpDataToPass.usermeta[14].UserValue
      : ""
  );
  const [
    startupContactDetailsErrorMessageVisibility,
    setStartupContactDetailsErrorMessageVisibility,
  ] = useState(false);
  const [
    startupContactDetailsErrorMessage,
    setStartupContactDetailsErrorMessage,
  ] = useState("");

  var [startupPlanOnRaisingFund, setStartupPlanOnRaisingFund] = useState(
    location.state && location.state.startUpDataToPass.usermeta[15]
      ? { label: location.state.startUpDataToPass.usermeta[15].UserValue }
      : 0
  );
  const [
    startupPlanOnRaisingFundErrorMessageVisibility,
    setStartupPlanOnRaisingFundErrorMessageVisibility,
  ] = useState(false);
  const [
    startupPlanOnRaisingFundErrorMessage,
    setStartupPlanOnRaisingFundErrorMessage,
  ] = useState("");

  var [startupFundingBefore, setStartupFundingBefore] = useState(
    location.state && location.state.startUpDataToPass.usermeta[16]
      ? { label: location.state.startUpDataToPass.usermeta[16].UserValue }
      : 0
  );
  const [
    startupFundingBeforeErrorMessageVisibility,
    setStartupFundingBeforeErrorMessageVisibility,
  ] = useState(false);
  const [
    startupFundingBeforeErrorMessage,
    setStartupFundingBeforeErrorMessage,
  ] = useState("");

  const [startupImage, setStartupImage] = useState(
    location.state ? location.state.startUpDataToPass.attachment_path : null
  );
  const [startup_ImageBae64, setStartUp_ImageBase64] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region API Calls
  const startUpUsermetaInfo = (Id, userId, userKey, userValue) => {
    return {
      Id: Id,
      UserId: userId,
      UserKey: userKey,
      UserValue: userValue,
    };
  };
  const addEditStartUpApiCall = () => {
    let data = {
      Id: startUpId ? startUpId : 0,
      UserType: USER_KEYS.STARTUP_USER_TYPE,
      Email: startupEmail,
      attachmnet_base64: startup_ImageBae64,
      attachment_path: isEdit() ? startupImage : null,
      usermeta: [
        startUpUsermetaInfo(
          0,
          startupUserId ? startupUserId : 0,
          USER_KEYS.STARTUP_NAME,
          startupName
        ),
        startUpUsermetaInfo(
          0,
          startupUserId,
          USER_KEYS.STARTUP_WEBSITE,
          startupWebsite
        ),
        startUpUsermetaInfo(
          0,
          startupUserId,
          USER_KEYS.STARTUP_INDUSTRY,
          startupIndustry.label
        ),
        startUpUsermetaInfo(
          0,
          startupUserId,
          USER_KEYS.STARTUP_STAGE,
          startupStage.label
        ),
        startUpUsermetaInfo(
          0,
          startupUserId,
          USER_KEYS.STARTUP_MODEL,
          startupModel.label
        ),
        startUpUsermetaInfo(
          0,
          startupUserId,
          USER_KEYS.STARTUP_PROBLEM,
          startupProblem
        ),
        startUpUsermetaInfo(
          0,
          startupUserId,
          USER_KEYS.STARTUP_DESCRIPTION,
          startupDescription
        ),
        startUpUsermetaInfo(
          0,
          startupUserId,
          USER_KEYS.STARTUP_TECHNOLOGY,
          startupTechnology
        ),
        startUpUsermetaInfo(
          0,
          startupUserId,
          USER_KEYS.STARTUP_LOCAL_COMPETITORS,
          startupLocalCompetitors
        ),
        startUpUsermetaInfo(
          0,
          startupUserId,
          USER_KEYS.STARTUP_GLOBAL_COMPETITORS,
          startupGlobalCompetitors
        ),
        startUpUsermetaInfo(
          0,
          startupUserId,
          USER_KEYS.STARTUP_UVP,
          startupUniqueValueProposition
        ),
        startUpUsermetaInfo(
          0,
          startupUserId,
          USER_KEYS.STARTUP_TRACTION,
          startupTraction
        ),
        startUpUsermetaInfo(
          0,
          startupUserId,
          USER_KEYS.STARTUP_TOTAL_MEMBERS_OF_FUNDING_TEAM,
          startupTotalMembersOfFundingTeam
        ),
        startUpUsermetaInfo(
          0,
          startupUserId,
          USER_KEYS.STARTUP_CONTACT_DETAILS,
          startupContactDetails
        ),
        startUpUsermetaInfo(
          0,
          startupUserId,
          USER_KEYS.STARTUP_FUNDING_TEAM_MEMBERS_DETAILS,
          startupDetailsOfFundingTeamMembers
        ),
        startUpUsermetaInfo(
          0,
          startupUserId,
          USER_KEYS.STARTUP_PLAN_ON_RAISING_FUND,
          startupPlanOnRaisingFund.label
        ),
        startUpUsermetaInfo(
          0,
          startupUserId,
          USER_KEYS.STARTUP_FUND_RAISED_BEFORE,
          startupFundingBefore.label
        ),
      ],
    };
    debugger;
    if (isViewValid()) {
      setIsLoading(true);
      addStartUp(data)
        .then((res) => {
          setIsLoading(false);
          debugger;
          if (res.data.success === ERROR_CODES.SUCCESS) {
            debugger;
            if (isEdit()) {
              setStartupArray(
                location.state.startUpDataToPass,
                res.data.result
              );
              navigate("/Dashboard/startup");

              debugger;
            } else {
              setStartupArray(res.data.result);
              navigate("/Dashboard/startup");

              debugger;
            }
            // getStartupArray().push(res.data.result)
          } else {
            alert("cant add data, please try later");
          }
        })
        .catch((error) => {
          alert("Exception", error);
        });
    }
  };

  //#endregion

  //#region other functions
  const fileSelectedHandler = async (event) => {
    if (event.currentTarget.files.length > 0){
    const element = event.currentTarget.files[0];
    await getBase64(element, (result) => {
      setStartUp_ImageBase64(result);
    });
  }
  };

  //#endregion

  //#region Startup Name Validation
  const onStartupNameTextChangeListener = (event) => {
    setStartupName((startupName = event.currentTarget.value));
    startupNameErrorMessageAndVisibilityAndIsValid(startupName);
  };

  const startupNameErrorMessageAndVisibilityAndIsValid = (enteredTexet) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredTexet)) {
      startupNameErrorMessageAndVisibility(true, "Name must not be empty");
    } else {
      startupNameErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;
  };

  const startupNameErrorMessageAndVisibility = (visibility, text) => {
    setStartupNameErrorMessageVisibility(visibility);
    setStartupNameErrorMessage(text);
  };
  //#endregion

  //#region Startup Email Validation
  const onStartupEmailTextChangeListener = (event) => {
    setStartupEmail((startupEmail = event.currentTarget.value));
    startupEmailErrorMessageAndVisibilityAndIsValid(startupEmail);
  };

  const startupEmailErrorMessageAndVisibilityAndIsValid = (enteredTexet) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredTexet)) {
      startupEmailErrorMessageAndVisibility(true, "Email must not be empty");
    } else if (isEmailInvalid(enteredTexet)) {
      startupEmailErrorMessageAndVisibility(true, "Enter valid email");
    } else {
      startupEmailErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;
  };

  const startupEmailErrorMessageAndVisibility = (visibility, text) => {
    setStartupEmailErrorMessageVisibility(visibility);
    setStartupEmailErrorMessage(text);
  };
  //#endregion

  //#region Startup Website Validation
  const onStartupWebsiteTextChangeListener = (event) => {
    setStartupWebsite((startupWebsite = event.currentTarget.value));
    startupWebsiteErrorMessageAndVisibilityAndIsValid(startupWebsite);
  };

  const startupWebsiteErrorMessageAndVisibilityAndIsValid = (enteredTexet) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredTexet)) {
      startupWebsiteErrorMessageAndVisibility(
        true,
        "website must not be empty"
      );
    } else if (isURLInvalid(enteredTexet)) {
      startupWebsiteErrorMessageAndVisibility(true, "Enter a valid URL");
    } else {
      startupWebsiteErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;
  };

  const startupWebsiteErrorMessageAndVisibility = (visibility, text) => {
    setStartupWebsiteErrorMessageVisibility(visibility);
    setStartupWebsiteErrorMessage(text);
  };
  //#endregion

  //#region Startup Industry
  const onStartupIndustryTextChangeListener = (event) => {
    setStartupIndustry((startupIndustry = event));
    startupIndustryErrorMessageAndVisibilityAndIsValid(startupIndustry);
  };

  const startupIndustryErrorMessageAndVisibilityAndIsValid = (enteredTexet) => {
    var isViewValid = false;
    if (enteredTexet === 0) {
      startupIndustryErrorMessageAndVisibility(
        true,
        "Industry must not be empty"
      );
    } else {
      startupIndustryErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;
  };

  const startupIndustryErrorMessageAndVisibility = (visibility, text) => {
    setStartupIndustryErrorMessageVisibility(visibility);
    setStartupIndustryErrorMessage(text);
  };

  //#endregion

  //#region Startup Stage Validation
  const onStartupStageTextChangeListener = (event) => {
    setStartupStage((startupStage = event));
    startupStageErrorMessageAndVisibilityAndIsValid(startupStage);
  };

  const startupStageErrorMessageAndVisibilityAndIsValid = (enteredTexet) => {
    var isViewValid = false;
    if (enteredTexet === 0) {
      startupStageErrorMessageAndVisibility(
        true,
        "startup stage must not be empty"
      );
    } else {
      startupStageErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;
  };

  const startupStageErrorMessageAndVisibility = (visibility, text) => {
    setStartupStageErrorMessageVisibility(visibility);
    setStartupStageErrorMessage(text);
  };
  //#endregion

  //#region Startup Model
  const onStartupModelTextChangeListener = (event) => {
    setStartupModel((startupModel = event));
    startupModelErrorMessageAndVisibilityAndIsValid(startupModel);
  };

  const startupModelErrorMessageAndVisibilityAndIsValid = (enteredTexet) => {
    var isViewValid = false;
    if (enteredTexet === 0) {
      startupModelErrorMessageAndVisibility(
        true,
        "startup model must not be empty"
      );
    } else {
      startupModelErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;
  };

  const startupModelErrorMessageAndVisibility = (visibility, text) => {
    setStartupModelErrorMessageVisibility(visibility);
    setStartupModelErrorMessage(text);
  };
  //#endregion

  //#region startup Problem
  const onStartupProblemTextChangeListener = (event) => {
    setStartupProblem((startupProblem = event.currentTarget.value));
    startupProblemErrorMessageAndVisibilityAndIsValid(startupProblem);
  };

  const startupProblemErrorMessageAndVisibilityAndIsValid = (enteredTexet) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredTexet)) {
      startupProblemErrorMessageAndVisibility(
        true,
        "startup problem must not be empty"
      );
    } else {
      startupProblemErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;
  };

  const startupProblemErrorMessageAndVisibility = (visibility, text) => {
    setStartupProblemErrorMessageVisibility(visibility);
    setStartupProblemErrorMessage(text);
  };

  //#endregion

  //#region Startup Description
  const onStartupDescriptionTextChangeListener = (event) => {
    setStartupDescription((startupDescription = event.currentTarget.value));
    startupDescriptionErrorMessageAndVisibilityAndIsValid(startupDescription);
  };

  const startupDescriptionErrorMessageAndVisibilityAndIsValid = (
    enteredTexet
  ) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredTexet)) {
      startupDescriptionErrorMessageAndVisibility(
        true,
        "Description must not be empty"
      );
    } else {
      startupDescriptionErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;
  };

  const startupDescriptionErrorMessageAndVisibility = (visibility, text) => {
    setStartupDescriptionErrorMessageVisibility(visibility);
    setStartupDescriptionErrorMessage(text);
  };
  //#endregion

  //#region Startup Technology
  const onStartupTechnologyTextChangeListener = (event) => {
    setStartupTechnology((startupTechnology = event.currentTarget.value));
    startupTechnologyErrorMessageAndVisibilityAndIsValid(startupTechnology);
  };

  const startupTechnologyErrorMessageAndVisibilityAndIsValid = (
    enteredTexet
  ) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredTexet)) {
      startupTechnologyErrorMessageAndVisibility(
        true,
        "Technology must not be empty"
      );
    } else {
      startupTechnologyErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;
  };

  const startupTechnologyErrorMessageAndVisibility = (visibility, text) => {
    setStartupTechnologyErrorMessageVisibility(visibility);
    setStartupTechnologyErrorMessage(text);
  };
  //#endregion

  //#region Startup Local Competitors Validation
  const onStartupLocalCompetitorsTextChangeListener = (event) => {
    setStartupLocalCompetitors(
      (startupLocalCompetitors = event.currentTarget.value)
    );
    startupLocalCompetitorsErrorMessageAndVisibilityAndIsValid(
      startupLocalCompetitors
    );
  };

  const startupLocalCompetitorsErrorMessageAndVisibilityAndIsValid = (
    enteredTexet
  ) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredTexet)) {
      startupLocalCompetitorsErrorMessageAndVisibility(
        true,
        "Local Competitors must not be empty"
      );
    } else {
      startupLocalCompetitorsErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;
  };

  const startupLocalCompetitorsErrorMessageAndVisibility = (
    visibility,
    text
  ) => {
    setStartupLocalCompetitorsErrorMessageVisibility(visibility);
    setStartupLocalCompetitorsErrorMessage(text);
  };
  //#endregion

  //#region Startup Global Competitors
  const onStartupGloablCompetitorsTextChangeListener = (event) => {
    setStartupGlobalCompetitors(
      (startupGlobalCompetitors = event.currentTarget.value)
    );
    startupGlobalCompetitorsErrorMessageAndVisibilityAndIsValid(
      startupGlobalCompetitors
    );
  };

  const startupGlobalCompetitorsErrorMessageAndVisibilityAndIsValid = (
    enteredTexet
  ) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredTexet)) {
      startupGlobalCompetitorsErrorMessageAndVisibility(
        true,
        "Global Competitors must not be empty"
      );
    } else {
      startupGlobalCompetitorsErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;
  };

  const startupGlobalCompetitorsErrorMessageAndVisibility = (
    visibility,
    text
  ) => {
    setStartupGlobalCompetitorsErrorMessageVisibility(visibility);
    setStartupGlobalCompetitorsErrorMessage(text);
  };
  //#endregion

  //#region Startup UVP Validation
  const onStartupUniqueValuePropositionTextChangeListener = (event) => {
    setStartupUniqueValueProposition(
      (startupUniqueValueProposition = event.currentTarget.value)
    );
    startupUniqueValuePropositionErrorMessageAndVisibilityAndIsValid(
      startupUniqueValueProposition
    );
  };

  const startupUniqueValuePropositionErrorMessageAndVisibilityAndIsValid = (
    enteredTexet
  ) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredTexet)) {
      startupUniqueValuePropositionErrorMessageAndVisibility(
        true,
        "UVP must not be empty"
      );
    } else {
      startupUniqueValuePropositionErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;
  };

  const startupUniqueValuePropositionErrorMessageAndVisibility = (
    visibility,
    text
  ) => {
    setStartupUniqueValuePropositionErrorMessageVisibility(visibility);
    setStartupUniqueValuePropositionErrorMessage(text);
  };
  //#endregion

  //#region Startup Traction Validation
  const onStartupTractionTextChangeListener = (event) => {
    setStartupTraction((startupTraction = event.currentTarget.value));
    startupTractionErrorMessageAndVisibilityAndIsValid(startupTraction);
  };

  const startupTractionErrorMessageAndVisibilityAndIsValid = (enteredTexet) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredTexet)) {
      startupTractionErrorMessageAndVisibility(
        true,
        "Traction must not be empty"
      );
    } else {
      startupTractionErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;
  };

  const startupTractionErrorMessageAndVisibility = (visibility, text) => {
    setStartupTractionErrorMessageVisibility(visibility);
    setStartupTractionErrorMessage(text);
  };
  //#endregion

  //#region Startup Total Members Of Funding Team Validation
  const onStartupTotalMembersOfFundingTeamTextChangeListener = (event) => {
    setStartupTotalMembersOfFundingTeam(
      (startupTotalMembersOfFundingTeam = event.currentTarget.value)
    );
    startupTotalMembersOfFundingTeamErrorMessageAndVisibilityAndIsValid(
      startupTotalMembersOfFundingTeam
    );
  };

  const startupTotalMembersOfFundingTeamErrorMessageAndVisibilityAndIsValid = (
    enteredTexet
  ) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredTexet)) {
      startupTotalMembersOfFundingTeamErrorMessageAndVisibility(
        true,
        "Funding team must not be empty"
      );
    } else {
      startupTotalMembersOfFundingTeamErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;
  };

  const startupTotalMembersOfFundingTeamErrorMessageAndVisibility = (
    visibility,
    text
  ) => {
    setStartupTotalMembersOfFundingTeamErrorMessageVisibility(visibility);
    setStartupTotalMembersOfFundingTeamErrorMessage(text);
  };
  //#endregion

  //#region Startup Contact Details Of Funding Team Validation
  const onStartupFoundingTeamContactDetailsTextChangeListener = (event) => {
    debugger;
    setStartupDetailsOfFundingTeamMembers(
      (startupDetailsOfFundingTeamMembers = event.currentTarget.value)
    );

    startupFoundingTeamContactDetailsErrorMessageAndVisibilityAndIsValid(
      startupDetailsOfFundingTeamMembers
    );
  };

  const startupFoundingTeamContactDetailsErrorMessageAndVisibilityAndIsValid = (
    enteredTexet
  ) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredTexet)) {
      startupFoundingTeamContactDetailsErrorMessageAndVisibility(
        true,
        "Founding team details must not be empty"
      );
      debugger;
    } else {
      startupFoundingTeamContactDetailsErrorMessageAndVisibility(false, "");
      isViewValid = true;
      debugger;
    }
    debugger;
    return isViewValid;
  };

  const startupFoundingTeamContactDetailsErrorMessageAndVisibility = (
    visibility,
    text
  ) => {
    setStartupDetailsOfFundingTeamMembersErrorMessageVisibility(visibility);
    setStartupDetailsOfFundingTeamMembersErrorMessage(text);
  };
  //#endregion

  //#region Startup Contact Details Validation
  const onStartupContactDetailsTextChangeListener = (event) => {
    setStartupContactDetails(
      (startupContactDetails = event.currentTarget.value)
    );
    startupContactDetailsErrorMessageAndVisibilityAndIsValid(
      startupContactDetails
    );
    debugger;
  };

  const startupContactDetailsErrorMessageAndVisibilityAndIsValid = (
    enteredTexet
  ) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredTexet)) {
      startupContactDetailsErrorMessageAndVisibility(
        true,
        "Contact details must not be empty"
      );
    } else {
      startupContactDetailsErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;
  };

  const startupContactDetailsErrorMessageAndVisibility = (visibility, text) => {
    setStartupContactDetailsErrorMessageVisibility(visibility);
    setStartupContactDetailsErrorMessage(text);
  };
  //#endregion

  //#region Startup Plan Validation
  const onStartupPlanOnRaisingFundTextChangeListener = (event) => {
    setStartupPlanOnRaisingFund((startupPlanOnRaisingFund = event));
    startupPlanOnRaisingFundErrorMessageAndVisibilityAndIsValid(
      startupPlanOnRaisingFund
    );
  };

  const startupPlanOnRaisingFundErrorMessageAndVisibilityAndIsValid = (
    enteredTexet
  ) => {
    var isViewValid = false;
    if (enteredTexet === 0) {
      startupPlanOnRaisingFundErrorMessageAndVisibility(
        true,
        "Plan must not be empty"
      );
    } else {
      startupPlanOnRaisingFundErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;
  };

  const startupPlanOnRaisingFundErrorMessageAndVisibility = (
    visibility,
    text
  ) => {
    setStartupPlanOnRaisingFundErrorMessageVisibility(visibility);
    setStartupPlanOnRaisingFundErrorMessage(text);
  };
  //#endregion

  //#region Startup Funding Validation
  const onStartupFundingBeforeTextChangeListener = (event) => {
    setStartupFundingBefore((startupFundingBefore = event));
    startupFundingBeforeErrorMessageAndVisibilityAndIsValid(
      startupFundingBefore
    );
  };

  const startupFundingBeforeErrorMessageAndVisibilityAndIsValid = (
    enteredTexet
  ) => {
    var isViewValid = false;
    if (enteredTexet === 0) {
      startupFundingBeforeErrorMessageAndVisibility(
        true,
        "field must not be empty"
      );
    } else {
      startupFundingBeforeErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;
  };

  const startupFundingBeforeErrorMessageAndVisibility = (visibility, text) => {
    setStartupFundingBeforeErrorMessageVisibility(visibility);
    setStartupFundingBeforeErrorMessage(text);
  };
  //#endregion

  //#region View Valid
  const isViewValid = () => {
    var viewValid = startupNameErrorMessageAndVisibilityAndIsValid(startupName);
    debugger;
    if (viewValid)
      viewValid =
        startupWebsiteErrorMessageAndVisibilityAndIsValid(startupWebsite);
    debugger;
    if (viewValid)
      viewValid =
        startupIndustryErrorMessageAndVisibilityAndIsValid(startupIndustry);
    debugger;
    if (viewValid)
      viewValid = startupStageErrorMessageAndVisibilityAndIsValid(startupStage);
    debugger;
    if (viewValid)
      viewValid = startupModelErrorMessageAndVisibilityAndIsValid(startupModel);
    debugger;
    if (viewValid)
      viewValid =
        startupProblemErrorMessageAndVisibilityAndIsValid(startupProblem);
    debugger;
    if (viewValid)
      viewValid =
        startupDescriptionErrorMessageAndVisibilityAndIsValid(
          startupDescription
        );
    debugger;
    if (viewValid)
      viewValid =
        startupTechnologyErrorMessageAndVisibilityAndIsValid(startupTechnology);
    debugger;
    if (viewValid)
      viewValid = startupLocalCompetitorsErrorMessageAndVisibilityAndIsValid(
        startupLocalCompetitors
      );
    debugger;
    if (viewValid)
      viewValid = startupGlobalCompetitorsErrorMessageAndVisibilityAndIsValid(
        startupGlobalCompetitors
      );
    debugger;
    if (viewValid)
      viewValid =
        startupUniqueValuePropositionErrorMessageAndVisibilityAndIsValid(
          startupUniqueValueProposition
        );
    debugger;
    if (viewValid)
      viewValid =
        startupTractionErrorMessageAndVisibilityAndIsValid(startupTraction);
    debugger;
    if (viewValid)
      viewValid =
        startupTotalMembersOfFundingTeamErrorMessageAndVisibilityAndIsValid(
          startupTotalMembersOfFundingTeam
        );
    debugger;
    if (viewValid)
      viewValid = startupContactDetailsErrorMessageAndVisibilityAndIsValid(
        startupContactDetails
      );
    debugger;
    if (viewValid)
      viewValid =
        startupFoundingTeamContactDetailsErrorMessageAndVisibilityAndIsValid(
          startupDetailsOfFundingTeamMembers
        );
    debugger;
    if (viewValid)
      viewValid = startupPlanOnRaisingFundErrorMessageAndVisibilityAndIsValid(
        startupPlanOnRaisingFund
      );
    debugger;
    if (viewValid)
      viewValid =
        startupFundingBeforeErrorMessageAndVisibilityAndIsValid(
          startupFundingBefore
        );
    debugger;

    return viewValid;
  };
  //#endregion

  return (
    <div className="main__container">
      {
        isLoading ? (
          <Loader/>
        ) : (
          <>

      <div className="main__container__header">
        <div className="main__container__header__heading">Startup</div>
        <div className="main__container__header__buttons"></div>
      </div>
      <div className="main__container__sub__heading">
        <button
          onClick={() => {
            navigate("/Dashboard/startup");
          }}
          className="main__container__sub__heading__btn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20.556"
            height="17.629"
            viewBox="0 0 20.556 17.629"
          >
            <g id="_507257" data-name="507257" transform="translate(0 -31.842)">
              <g
                id="Group_506"
                data-name="Group 506"
                transform="translate(0 31.842)"
              >
                <path
                  id="Path_753"
                  data-name="Path 753"
                  d="M19.32,39.213a1.469,1.469,0,0,0-.254-.019H4.564l.316-.147a2.941,2.941,0,0,0,.831-.588l4.067-4.067a1.522,1.522,0,0,0,.213-1.949,1.471,1.471,0,0,0-2.206-.191L.431,39.606a1.471,1.471,0,0,0,0,2.08h0l7.354,7.354a1.471,1.471,0,0,0,2.206-.147,1.522,1.522,0,0,0-.213-1.949L5.718,42.871a2.942,2.942,0,0,0-.735-.537l-.441-.2H18.984A1.522,1.522,0,0,0,20.536,40.9,1.471,1.471,0,0,0,19.32,39.213Z"
                  transform="translate(0 -31.842)"
                  fill="#d39844"
                />
              </g>
            </g>
          </svg>
        </button>
        {isEdit() ? "Edit Startup" : "Add Startup"}
      </div>
      <div className="main__container__content">
        <div className="add__startup__main__container__content" action="">
          <div className="add__startup__main__container__content__entry">
            <div className="add__startup__main__container__content__entry__content">
              <div className="add__startup__main__container__content__entry__content__heading">
                Start-Up Name
              </div>
              <InputBox
                placeholder="Name"
                type="text"
                value={startupName}
                error={startupNameErrorMessageVisibility}
                errorMessage={startupNameErrorMessage}
                onChange={(event) => onStartupNameTextChangeListener(event)}
              />
            </div>
            <div className="add__startup__main__container__content__entry__content">
              <div className="add__startup__main__container__content__entry__content__heading">
                Start-Up Email
              </div>
              <InputBox
                placeholder="Email"
                type="text"
                value={startupEmail}
                error={startupEmailErrorMessageVisibility}
                errorMessage={startupEmailErrorMessage}
                onChange={(event) => onStartupEmailTextChangeListener(event)}
              />
            </div>
          </div>
          <div className="add__startup__main__container__content__entry">
            <div className="add__startup__main__container__content__entry__content">
              <div className="add__startup__main__container__content__entry__content__heading">
                Website/Page URL
              </div>
              <InputBox
                placeholder="Website"
                type="text"
                value={startupWebsite}
                error={startupWebsiteErrorMessageVisibility}
                errorMessage={startupWebsiteErrorMessage}
                onChange={(event) => onStartupWebsiteTextChangeListener(event)}
              />
            </div>
            {/* <div className="add__startup__main__container__content__entry__content">
              <div className="add__startup__main__container__content__entry__content__heading">
                Website/Page URL
              </div>
              <InputBox
                placeholder="Website"
                type="text"
                value={startupWebsite}
                onChange={(event) => setStartupWebsite(event.currentTarget.value)}
              />
            </div> */}
          </div>
          <div className="add__startup__main__container__content__entry">
            <div className="add__startup__main__container__content__entry__content">
              <div className="add__startup__main__container__content__entry__content__heading">
                Start-Up Industry
              </div>
              <InputBox
                placeholder="Industry"
                type="text"
                variant="select"
                options={startupIndustryOptions}
                value={startupIndustry}
                error={startupIndustryErrorMessageVisibility}
                errorMessage={startupIndustryErrorMessage}
                onChange={(event) => onStartupIndustryTextChangeListener(event)}
              />
            </div>
            <div className="add__startup__main__container__content__entry__content">
              <div className="add__startup__main__container__content__entry__content__heading">
                What Stage Is Your Start-Up At?
              </div>
              <InputBox
                placeholder="Startup Stage"
                type="text"
                variant="select"
                options={startupStageOptions}
                value={startupStage}
                error={startupStageErrorMessageVisibility}
                errorMessage={startupStageErrorMessage}
                onChange={(event) => onStartupStageTextChangeListener(event)}
              />
            </div>
          </div>
          <div className="add__startup__main__container__content__entry">
            <div className="add__startup__main__container__content__entry__content">
              <div className="add__startup__main__container__content__entry__content__heading">
                What Model Does Your Start-Up Operate In?
              </div>
              <InputBox
                placeholder="Startup Model"
                type="text"
                variant="select"
                options={startupModelOptions}
                value={startupModel}
                error={startupModelErrorMessageVisibility}
                errorMessage={startupModelErrorMessage}
                onChange={(event) => onStartupModelTextChangeListener(event)}
              />
            </div>

            <div className="add__startup__main__container__content__entry__content">
              <div className="add__startup__main__container__content__entry__content__heading">
                What Problem Is Your Start-Up Solving?
              </div>
              <InputBox
                placeholder="Problem Solving Startup"
                type="text"
                value={startupProblem}
                error={startupProblemErrorMessageVisibility}
                errorMessage={startupProblemErrorMessage}
                onChange={(event) => onStartupProblemTextChangeListener(event)}
              />
            </div>
          </div>
          <div className="add__startup__main__container__content__entry">
            <div className="add__startup__main__container__content__entry__content">
              <div className="add__startup__main__container__content__entry__content__heading">
                Describe Your Start-Up In 2-3 Lines.
              </div>
              <InputBox
                placeholder="Your Answer"
                type="text"
                value={startupDescription}
                error={startupDescriptionErrorMessageVisibility}
                errorMessage={startupDescriptionErrorMessage}
                onChange={(event) =>
                  onStartupDescriptionTextChangeListener(event)
                }
              />
            </div>

            <div className="add__startup__main__container__content__entry__content">
              <div className="add__startup__main__container__content__entry__content__heading">
                How is your start-up incorporating technology?
              </div>
              <InputBox
                placeholder="Your Answer"
                type="text"
                value={startupTechnology}
                error={startupTechnologyErrorMessageVisibility}
                errorMessage={startupTechnologyErrorMessage}
                onChange={(event) =>
                  onStartupTechnologyTextChangeListener(event)
                }
              />
            </div>
          </div>
          <div className="add__startup__main__container__content__entry">
            <div className="add__startup__main__container__content__entry__content">
              <div className="add__startup__main__container__content__entry__content__heading">
                Who Are Your Local Competitors?
              </div>
              <InputBox
                placeholder="Your Answer"
                type="text"
                value={startupLocalCompetitors}
                error={startupLocalCompetitorsErrorMessageVisibility}
                errorMessage={startupLocalCompetitorsErrorMessage}
                onChange={(event) =>
                  onStartupLocalCompetitorsTextChangeListener(event)
                }
              />
            </div>

            <div className="add__startup__main__container__content__entry__content">
              <div className="add__startup__main__container__content__entry__content__heading">
                Who Are Your Global Competitors?
              </div>
              <InputBox
                placeholder="Your Answer"
                type="text"
                value={startupGlobalCompetitors}
                error={startupGlobalCompetitorsErrorMessageVisibility}
                errorMessage={startupGlobalCompetitorsErrorMessage}
                onChange={(event) =>
                  onStartupGloablCompetitorsTextChangeListener(event)
                }
              />
            </div>
          </div>
          <div className="add__startup__main__container__content__entry">
            <div className="add__startup__main__container__content__entry__content">
              <div className="add__startup__main__container__content__entry__content__heading">
                What Makes Your Start-Up Unique From Other Available Solutions
              </div>
              <InputBox
                placeholder="Your Answer"
                type="text"
                value={startupUniqueValueProposition}
                error={startupUuniqueValuePropositionErrorMessageVisibility}
                errorMessage={startupUniqueValuePropositionErrorMessage}
                onChange={(event) =>
                  onStartupUniqueValuePropositionTextChangeListener(event)
                }
              />
            </div>

            <div className="add__startup__main__container__content__entry__content">
              <div className="add__startup__main__container__content__entry__content__heading">
                Does Your Start-Up Have Traction? If Yes, How Much?
              </div>
              <InputBox
                placeholder="Your Answer"
                type="text"
                value={startupTraction}
                error={startupTractionErrorMessageVisibility}
                errorMessage={startupTractionErrorMessage}
                onChange={(event) => onStartupTractionTextChangeListener(event)}
              />
            </div>
          </div>
          {/* <div className="add__startup__main__container__content__entry">
            <div className="add__startup__main__container__content__entry__content">
              <div className="add__startup__main__container__content__entry__content__heading">
                Add Details Of The Founding Team Member(S), Including Founder(S)
                And, If Applicable, Co-Founder(S). Give Their Academic
                Backgrounds And Any Relevant Experience.
              </div>
              <InputBox placeholder="Your Answer" type="text" />
            </div>
          </div> */}
          <div className="add__startup__main__container__content__entry">
            <div className="add__startup__main__container__content__entry__content">
              <div className="add__startup__main__container__content__entry__content__heading">
                Total Members Of Founding Team:
              </div>
              <InputBox
                placeholder="Your Answer"
                type="text"
                value={startupTotalMembersOfFundingTeam}
                error={startupTotalMembersOfFundingTeamErrorMessageVisibility}
                errorMessage={startupTotalMembersOffundingTeamErrorMessage}
                onChange={(event) =>
                  onStartupTotalMembersOfFundingTeamTextChangeListener(event)
                }
              />
            </div>

            <div className="add__startup__main__container__content__entry__content">
              <div className="add__startup__main__container__content__entry__content__heading">
                Please Give Contact Details To Get In Touch (Email/Phone Number)
              </div>
              <InputBox
                placeholder="Your Answer"
                type="text"
                value={startupContactDetails}
                error={startupContactDetailsErrorMessageVisibility}
                errorMessage={startupContactDetailsErrorMessage}
                onChange={(event) =>
                  onStartupContactDetailsTextChangeListener(event)
                }
              />
            </div>
          </div>
          <div className="add__startup__main__container__content__entry">
            <div className="add__startup__main__container__content__entry__content">
              <div className="add__startup__main__container__content__entry__content__heading">
                Add Details Of The Founding Team Member(S), Including Founder(S)
                And, If Applicable, Co-Founder(S). Give Their Academic
                Backgrounds And Any Relevant Experience.
              </div>
              <InputBox
                placeholder="Your Answer"
                type="text"
                value={startupDetailsOfFundingTeamMembers}
                error={startupDetailsOfFundingTeamMembersErrorMessageVisibility}
                errorMessage={startupDetailsOfFundingTeamMembersErrorMessage}
                onChange={(event) =>
                  onStartupFoundingTeamContactDetailsTextChangeListener(event)
                }
              />
            </div>
          </div>
          <div className="add__startup__main__container__content__entry">
            <div className="add__startup__main__container__content__entry__content">
              <div className="add__startup__main__container__content__entry__content__heading">
                When Does Your Start-Up Plan On Raising Funding?
              </div>
              <InputBox
                placeholder="Startup Plan"
                type="text"
                variant="select"
                options={startupPlanOnFundingOptions}
                value={startupPlanOnRaisingFund}
                error={startupPlanOnRaisingFundErrorMessageVisibility}
                errorMessage={startupPlanOnRaisingFundErrorMessage}
                onChange={(event) =>
                  onStartupPlanOnRaisingFundTextChangeListener(event)
                }
              />
            </div>

            <div className="add__startup__main__container__content__entry__content">
              <div className="add__startup__main__container__content__entry__content__heading">
                Has Your Start-Up Raised Funding Before?
              </div>
              <InputBox
                placeholder="Startup Funding Fee "
                type="text"
                variant="select"
                options={startupFundingBeforeOptions}
                value={startupFundingBefore}
                error={startupFundingBeforeErrorMessageVisibility}
                errorMessage={startupFundingBeforeErrorMessage}
                onChange={(event) =>
                  onStartupFundingBeforeTextChangeListener(event)
                }
              />
            </div>
          </div>
          <div className="add__startup__main__container__content__entry__content__heading">
            Upload Image
          </div>
          {location.state ? (
            <InputBox
              variant="upload"
              src={
                startup_ImageBae64 === null
                  ? process.env.REACT_APP_API_URL + startupImage
                  : startup_ImageBae64
              }
              onChange={fileSelectedHandler}
            />
          ) : (
            <>
              <InputBox
                variant="upload"
                src={startup_ImageBae64}
                onChange={fileSelectedHandler}
              />
            </>
          )}
          {/* <div className="add__startup__main__container__content__entry__content__heading">Answer</div>
        <textarea
          cols="30"
          rows="6"
          placeholder="Branch"
          className="table__details__container__text__box__input"
        /> */}
          <div className="popup__cta__button">
            <button
              onClick={() => addEditStartUpApiCall()}
              style={{
                marginTop: "2em",
                marginBottom: "1em",
              }}
              className="primary__button"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
          </>
        )
      }
    </div>
  );
}
